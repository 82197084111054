import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Log in on the internal platform': 'Log in on the internal platform',
      'Internal server error': 'Internal server error, please try again later',
      'Invalid authorization token': 'Invalid authorization token',
      Timeout:
        'The request timed out, maybe the server is busy ? Please try again later',
      'Log in': 'Log in',
      'Forgot your password ?': 'Forgot your password ?',
      'Create new account': 'Create new account',
      'Must be a valid email': 'Must be a valid email',
      'Email is required': 'Email is required',
      'Password is required': 'Password is required',
      'Reset Password': 'Reset Password',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Log In': 'Log In',
      'The informations you provided are incorrect.':
        'The informations you provided are incorrect.',
      'Team management': 'Team management',
      Roles: 'Roles',
      Users: 'Users',
      'Manage Users': 'Manage Users',
      'User List': 'User List',
      Dashboard: 'Dashboard',
      Management: 'Management',
      Export: 'Export',
      Add: 'Add',
      Edit: 'Edit',
      'a user': 'a user',
      'the user': 'user',
      User: 'User',
      Search: 'Search',
      'Sort By': 'Sort By',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Role: 'Role',
      Phone: 'Phone number',
      Job: 'Job',
      Email: 'Email',
      years: 'years',
      months: 'months',
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      'Just now': 'Just now',
      'Last connected': 'Last connected',
      'Fetching data': 'Fetching data',
      'Error while fetching data': 'Error while fetching data',
      Active: 'Active',
      Added: 'Ajouté',
      'Adding data': 'Ajout des données',
      'Error while adding the data': "Erreur d'ajout des données",
      'Something went wrong': "Quelque chose s'est mal passé",
      'Disabling this will automatically disallow the user from connecting.':
        'Disabling this will automatically disallow the user from connecting.',
      'is not valid': 'is not valid',
      'You cannot delete this role':
        'You cannot delete this role because one or more users belong to it.\nThey must be assigned to a new role in order to be able to delete the current role.',
      'Are you sure?':
        'Are you sure you want to do this ? This action cannot be undone and is irreversible.',
      'We use your cam':
        "You are asked to record a response using your camera/webcam.\n\nYou can use the video screen to record and play a test video. Make sure you are in front of your camera and check if the video and audio are recorded correctly. Don't worry, these test videos are not stored and will be deleted.",
      'Recent Jobs': 'Recent Jobs',
      email: 'Email address',
      firstName: 'First Name',
      lastName: 'Last Name',
      and: 'et',
      _EXTEND_: '_EXTEND_'
    }
  },
  fr: {
    translation: {
      'Log in on the internal platform':
        'Connectez-vous sur la plateforme interne',
      'Internal server error':
        'Erreur interne du serveur, veuillez réessayer plus tard',
      'Invalid authorization token': "Jeton d'autorisation invalide",
      Timeout:
        'La requête a expiré, peut-être que le serveur est occupé ? Veuillez réessayer plus tard',
      'Log in': 'Se connecter',
      'Forgot your password ?': 'Mot de passe oublié ?',
      'Create new account': "Pas de compte ? S'inscrire",
      'Must be a valid email': 'Doit être un adresse email valide',
      'Email is required': "L'adresse mail est requise",
      'Password is required': 'Le mot de passe est requis',
      'Reset Password': 'Réinitialiser le mot de passe',
      'Email Address': 'Adresse mail',
      Password: 'Mot de passe',
      'Log In': 'Se connecter',
      'The informations you provided are incorrect.':
        'Les informations que vous avez fournies sont incorrectes.',
      'Team management': "Gestion de l'équipe",
      'Tests management': 'Gestion des tests',
      Roles: 'Rôles',
      Users: 'Utilisateurs',
      Jobs: 'Postes',
      'Please select a file to upload it can be (excel, word, or PDF)':
        'Veuillez sélectionner un fichier(excel, word ou PDF)',
      Applications: 'Candidatures',
      'Manage Users': 'Gestion des utilisateur',
      'Manage Tests': 'Gestion des tests',
      'User List': 'Liste des utilisateur',
      'Tests List': 'Liste des tests',
      Dashboard: 'Dashboard',
      Management: 'Gestion',
      Export: 'Export',
      Add: 'Ajouter',
      Content: 'Contenu',
      Options: 'Options',
      Edit: 'Modifier',
      'a user': 'un utilisateur',
      'a test': 'un test',
      User: 'Utilisateur',
      'the user': "l'utilisateur",
      Search: 'Recherche',
      'Sort By': 'Trier par',
      'First Name': 'Prenom',
      'Last Name': 'Nom',
      Role: 'Rôle',
      Phone: 'Numéro de téléphone',
      Job: 'Poste',
      Email: 'Email',
      years: 'années',
      months: 'mois',
      days: 'jours',
      hours: 'heures',
      minutes: 'minutes',
      'Just now': 'Juste maintenant',
      'Last connected': 'Dernière connexion',
      'Fetching data': 'Récupération des données',
      'Error while fetching data': 'Impossible de récupérer les données',
      Active: 'Actif',
      'Disabling this will automatically disallow the user from connecting.':
        "La désactivation de cette option empêchera automatiquement l'utilisateur de se connecter.",
      'is not valid': "n'est pas valide",
      'Allow to': 'Permet',
      Display: 'Afficher',
      display: "d'afficher",
      Create: 'Créer',
      create: 'de créer',
      edit: 'de modifier',
      Delete: 'Supprimer',
      delete: 'de supprimer',
      users: 'les utilisateurs',
      questions: 'les questions',
      fullRemote: 'Télétravail',
      noRemote: 'Présentiel',
      halfRemote: 'Télétravail partiel',
      jobType: 'Type de contrat',
      'Remote Policy': 'Télétravail',
      jobs: 'les postes',
      applications: 'les candidatures',
      'a role': 'un rôle',
      'the role': 'le rôle',
      'Name of role': 'Nom du rôle',
      'is required': 'est requis',
      'You cannot delete this role':
        'Vous ne pouvez supprimer ce rôle car un ou plusieurs utilisateurs en font partie.\nIl faut les affecter à un nouveau rôle pour pouvoir effacer le rôle actuel.\n',
      'The list of users who will be affected':
        'La liste des utilisateurs qui seront affectés:',
      'Affect users': 'Affecter les utilisateurs',
      Cancel: 'Annuler',
      'Affect users to this role': 'Affecter les utilisateur à ce rôle',
      'Are you sure?':
        'Êtes-vous sûr de vouloir faire cette action? Cette action est irrévérsible.',
      'Confirmation needed': 'Confirmation nécessaire',
      Confirm: 'Confirmer',
      'Request password': 'Demander un nouveau mot de passe',
      'Password Recovery': 'Récupération de mot de passe',
      'Tell us your email so we can send you a reset link':
        'Merci de saisir votre email afin que nous puissions vous envoyer un lien de réinitialisation',
      'Create a new password': 'Créer un nouveau mot de passe',
      'Password Reset': 'Réinitialisation du mot de passe',
      'Do you want to login ?': 'Voulez-vous vous connecter ?',
      'Resource Expired': 'Évaluation expirée ou verrouillée',
      'This evaluation has expired or is locked, Please send us an email if you think this is a mistake':
        "Cette évaluation a expiré ou est verrouillée, veuillez nous envoyer un e-mail si vous pensez qu'il s'agit d'une erreur",
      'Evaluation Expired or locked': 'Ressource expirée',
      'The token you are trying to use is either invalid or has expired':
        "Le jeton que vous essayez d'utiliser n'est pas valide ou a expiré",
      'Whichever it is, try to request a new token':
        "Quoi qu'il en soit, essayez de demander un nouveau jeton",
      'Use a few words, avoid common phrases':
        'Utilisez quelques mots, évitez les phrases courantes',
      'No need for symbols, digits, or uppercase letters':
        'Pas besoin de symboles, de chiffres ou de lettres majuscules',
      'No need to have such a long password':
        "Pas besoin d'avoir un mot de passe aussi long",
      'Add another word or two. Uncommon words are better.':
        'Ajoutez un autre mot ou deux. Les mots peu communs sont meilleurs.',
      'Straight rows of keys are easy to guess':
        'Les rangées droites de touches sont faciles à deviner',
      'Short keyboard patterns are easy to guess':
        'Les modèles de clavier courts sont faciles à deviner',
      'Use a longer keyboard pattern with more turns':
        'Utilisez un motif de clavier plus long avec plus de tours',
      'Repeats like "aaa" are easy to guess':
        'Les répétitions comme "aaa" sont faciles à deviner',
      'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
        'Les répétitions comme "abcabcabc" ne sont que légèrement plus difficiles à deviner que "abc"',
      'Avoid repeated words and characters':
        'Évitez les mots et les caractères répétés',
      'Sequences like abc or 6543 are easy to guess':
        'Les séquences comme abc ou 6543 sont faciles à deviner',
      'Recent years are easy to guess':
        'Les dernières années sont faciles à deviner',
      'Avoid recent years, Avoid years that are associated with you':
        'Évitez les années récentes, évitez les années qui vous sont associées',
      'Dates are often easy to guess':
        'Les dates sont souvent faciles à deviner',
      'Avoid dates and years that are associated with you':
        'Évitez les dates et les années qui vous sont associées',
      'This is a top-10 common password':
        "Il s'agit d'un top 10 des mots de passe communs",
      'Please allow your webcam': 'Veuillez autoriser votre webcam',
      'Please allow your microphone': 'Veuillez autoriser votre micro',
      'This is a top-100 common password':
        "Il s'agit d'un top 100 des mots de passe communs",
      'This is a very common password': "C'est un mot de passe très commun",
      'This is similar to a commonly used password':
        "C'est un mot de passe similaire à un mot de passe couramment utilisé",
      'A word by itself is easy to guess':
        'Un mot par lui-même est facile à deviner',
      'Names and surnames by themselves are easy to guess':
        'Les noms et prénoms par eux-mêmes sont faciles à deviner',
      'Common names and surnames are easy to guess':
        'Les noms communs et les noms de famille sont faciles à deviner',
      'Capitalization doesnt help very much':
        "Les majusculeurs seuls n'aident pas beaucoup",
      'All-uppercase is almost as easy to guess as all-lowercase':
        'Tout en majuscule est presque aussi facile à deviner que tout en minuscule',
      'Reversed words arent much harder to guess':
        'Les mots inversés ne sont pas beaucoup plus difficiles à deviner',
      'Predictable substitutions like @ instead of a dont help very much':
        "Les substitutions prévisibles comme @ au lieu de a n'aident pas beaucoup",
      'Very Weak': 'Très faible',
      Weak: 'Faible',
      Guessable: 'Devinable',
      Moderate: 'Fort',
      Strong: 'Élevé',
      'The password': 'Le mot de passe',
      Passwords: 'Les mots de passe',
      'must at least be': 'doit être au minimum',
      'must match': 'doivent correspondre.',
      true: 'Oui',
      false: 'Non',
      Category: 'Catégorie',
      Difficulty: 'Difficulté',
      'Apply Penalty': 'Avec penalité',
      'Allowed time': 'Temps alloué',
      'Tests where question is used': 'Tests où la question est utilisée',
      'Questions management': 'Gestion des questions',
      'Questions List': 'Liste des questions',
      All: 'Tous',
      Easy: 'Facile',
      Average: 'Moyen',
      Hard: 'Difficile',
      'Prior experience': 'Experience',
      'Soft skills': 'Compétences non techniques',
      'Hard skills': 'Compétences techniques',
      'Job skills': 'Compétences professionnelles',
      'General culture': 'Culture générale',
      'a question': 'une question',
      'Correct Answer': 'Réponse correcte',
      'The answer': 'La réponse',
      'Please select the correct answer':
        'Veuillez sélectionner la bonne réponse',
      'Please select at least 1 correct answer':
        'Veuillez sélectionner au moins une bonne réponse',
      answer: 'une réponse',
      Answer: 'Réponse',
      last_warning: 'Attention !',
      are_you_sure_empty_answer:
        "Êtes-vous sûr de vouloir ignorer cette question ? Votre candidature pourrait être écartée si vous décider d'ignorer cette question.",
      warning_switch_tabs:
        "Vous avez changer d'onglet durant votre évaluation, attention si vous le faite une deuxieme fois cette evaluation sera bloquée.",
      Name: 'Nom',
      No_permissions: "Veuillez autoriser l'utilisation de l'apareil.",
      'Used questions': 'Questions utilisées',
      slider_test_info:
        'Choisir le score minimum et/ou maximum pour un traitement automatique.',
      'min score': 'Score min',
      'max score': 'Score max',
      'is a required field': 'est un champ obligatoire',
      'We use your cam':
        "Il est demandé d'enregistrer une réponse à l'aide de votre caméra/webcam.\n\nVous pouvez utiliser l'écran vidéo pour enregistrer et lire une vidéo d'essai. Assurez-vous que vous êtes devant votre caméra et vérifiez si la vidéo et l'audio sont correctement enregistrés. Ne vous inquiétez pas, ces vidéos d'essai ne sont pas stockées et seront supprimés.",
      'Configure accepted response': 'Configurer la réponse acceptée',
      'Limit to': 'Limiter à',
      yes: 'Oui',
      no: 'Non',
      'Start Recording': "Commencer l'enregistrement",
      'Stop Recording': "Arrêter l'enregistrement",
      'Restart Recording': "Recommencer l'enregistrement",
      next_test: 'Test suivant',
      Complete: 'Terminer',
      'Take a break': 'Prendre une pause',
      Send: 'Envoyer',
      Words: 'Mots',
      Previous: 'Précédent',
      Next: 'Suivant',
      Characters: 'Caractères',
      'File size': 'Taille du fichier',
      Kilobytes: 'Kilooctets',
      Megabytes: 'Mégaoctets',
      Gigabytes: 'Gigaoctets',
      email: 'Adresse mail',
      firstName: 'Prénom',
      lastName: 'Nom',
      'Good luck': 'Bonne chance !',
      'Recent Jobs': '{{companyName}} recrute',
      _EXTEND_: '_EXTEND_'
    }
  }
};
i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false
  }
});
